
import {computed, defineComponent, ref} from 'vue'
import store from "@/store";
import {LoadFilterObjects} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import Cards from "@/components/base/common/Cards.vue";
import ProcessCard from "@/views/process/ProcessCard.vue";

export default defineComponent({
  name: "AllProcess",
  components: {ProcessCard, Cards},
  props: {
    objectType: {},
    objectId: {},
  },
  setup(props) {
    const objectFilter = ref({objectType: props.objectType, objectId: props.objectId})
    const page = computed(() => store.state.ProcessModule.allPage);
    return {
      page,
      objectFilter,
      ...LoadFilterObjects(Actions.LOAD_ALL_PROCESS, objectFilter.value, ['assigned'])
    }
  }
})
