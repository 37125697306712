
import {defineComponent, ref, watch} from 'vue'
import {PROCESS_TYPE} from "@/core/config/Constant";

export default defineComponent({
  name: "ProcessType",
  props: {
    type: {}
  },
  setup(props) {

    const data = ref({name: '', clazz: ''});

    const build = (type) => {
      switch (type) {
        case 'MEDICAL_REVIEW':
          data.value = {name: 'Medical Review', clazz: 'badge-success'}
          break
        case 'COMPLAINT':
          data.value = {name: 'Claim', clazz: 'badge-primary'}
          break
      }
    }
    build(props.type)

    watch(() => props.type, cb => {
      build(cb);
    })

    return {
      PROCESS_TYPE,
      data,
    }

  }
})
