
import {defineComponent} from 'vue'
import ProcessType from "@/views/process/ProcessType.vue";
import Row from "@/components/base/common/Row.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import ProcessStatus from "@/views/process/ProcessStatus.vue";
import Avatar from "@/components/base/common/Avatar.vue";

export default defineComponent({
  name: "ProcessCard",
  components: {Avatar, ProcessStatus, DateTimeFormat, Row, ProcessType},
  props: {
    process: {}
  },

})
