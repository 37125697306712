
import {defineComponent, ref, watch} from 'vue'
import BaseForm from "@/components/base/form/BaseForm.vue";
import FormErrorMessage from "@/components/base/form/FormErrorMessage.vue";
import UserListSelect from "@/components/common/UserListSelect.vue";
import Froala from "@/components/base/editor/Froala.vue";
import {Field} from "vee-validate";
import ProcessService from "@/core/services/ProcessService";

export default defineComponent({
  name: "ProcessForm",
  components: {Froala, UserListSelect, FormErrorMessage, BaseForm, Field},
  props: {
    process: {type: Object},
    id: {type: String},
  },
  emits: ['saved'],
  setup(props) {
    const model = ref(props.process);
    watch(() => props.process, cb => {
      model.value = cb;
    })

    const submitting = ref(false);
    return {
      submitting,
      model,
    }
  },
  methods: {
    onSubmit() {
      this.submitting = true;
      if (this.id) {
        ProcessService.update(this.id, this.model).then(res => {
          this.$emit('saved', res);
        }).finally(() => {
          this.submitting = false;
        })
      } else {
        ProcessService.create(this.model).then(res => {
          this.$emit('saved', res)
        }).finally(() => {
          this.submitting = false;
        })
      }
    }
  }
})
