import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "card-body" }
const _hoisted_5 = ["data-bs-original-title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ProcessType = _resolveComponent("ProcessType")!
  const _component_ProcessStatus = _resolveComponent("ProcessStatus")!
  const _component_DateTimeFormat = _resolveComponent("DateTimeFormat")!
  const _component_Row = _resolveComponent("Row")!
  const _component_Avatar = _resolveComponent("Avatar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_link, {
          to: `/processes/`+ _ctx.process.id
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.process.name), 1)
          ]),
          _: 1
        }, 8, ["to"]),
        _createVNode(_component_ProcessType, {
          type: _ctx.process.type
        }, null, 8, ["type"]),
        _createVNode(_component_ProcessStatus, {
          status: _ctx.process.status
        }, null, 8, ["status"])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_Row, { row: [{name:'Start Date', slot:true, key: 'startDate'}] }, {
        startDate: _withCtx(() => [
          _createVNode(_component_DateTimeFormat, {
            date: _ctx.process.dateCreated
          }, null, 8, ["date"])
        ]),
        _: 1
      }),
      _createVNode(_component_Row, { row: [{name:'Assigned', slot:true, key: 'assigned'}] }, {
        assigned: _withCtx(() => [
          _createElementVNode("div", {
            class: "symbol symbol-25px symbol-circle",
            "data-bs-toggle": "tooltip",
            title: "",
            "data-bs-original-title": _ctx.process?.assigned?.fullName
          }, [
            _createVNode(_component_Avatar, {
              avatar: _ctx.process?.assigned?.avatar,
              "user-id": _ctx.process?.assigned?.id
            }, null, 8, ["avatar", "user-id"])
          ], 8, _hoisted_5),
          _createTextVNode("  " + _toDisplayString(_ctx.process?.assigned?.fullName ? _ctx.process?.assigned?.fullName : ''), 1)
        ]),
        _: 1
      })
    ])
  ]))
}