import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProcessCard = _resolveComponent("ProcessCard")!
  const _component_Cards = _resolveComponent("Cards")!

  return (_openBlock(), _createBlock(_component_Cards, {
    total: _ctx.page.data.total,
    "card-data": _ctx.page.data.data,
    "current-page": _ctx.filter.paginationPage,
    "rows-per-page": _ctx.filter.paginationSize,
    onCurrentChange: _ctx.handleUpdatePage,
    stretched: ""
  }, {
    "card-info": _withCtx(({card: process}) => [
      _createVNode(_component_ProcessCard, { process: process }, null, 8, ["process"])
    ]),
    _: 1
  }, 8, ["total", "card-data", "current-page", "rows-per-page", "onCurrentChange"]))
}