
import {computed, defineComponent} from 'vue'
import store from "@/store";

export default defineComponent({
  name: "ProcessSummary",
  setup() {
    const active = computed(() => store.state.ProcessModule.activePage)
    const complete = computed(() => store.state.ProcessModule.completedPage)
    return {
      active,
      complete,
    }
  }

})
