
import {defineComponent, ref} from 'vue'
import AllProcess from "@/views/process/AllProcess.vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import ProcessForm from "@/views/process/ProcessForm.vue";
import ActiveProcess from "@/views/process/ActiveProcess.vue";
import CompleteProcess from "@/views/process/CompleteProcess.vue";
import ProcessSummary from "@/views/process/ProcessSummary.vue";
import store from "@/store";
import {Mutations} from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "ProcessList",
  components: {ProcessSummary, CompleteProcess, ActiveProcess, ProcessForm, BaseModal, QuickAction, AllProcess},
  props: {
    objectType: {type: String, required: true},
    objectId: {type: String, required: true}
  },
  setup() {
    const state = ref({
      processName: '',
      process: {type: '', assignedIdRequest: '', objectIdRequest: '', objectTypeRequest: '', description: '', name: '', notification: false}
    });
    return {
      state,
    }
  },
  methods: {
    onStartProcess(type) {
      switch (type) {
        case  'MEDICAL_REVIEW':
          this.state.processName = 'Start Medical Review Process'
          break
        case 'COMPLAINT':
          this.state.processName = 'Start Complaint Process'
          break
        case 'INQUIRY':
          this.state.processName = 'Start Inquiry Process'
          break
        case 'APPEAL':
          this.state.processName = 'Start Appeal Process'
          break
        case 'ISSUE':
          this.state.processName = 'Start Issue Process'
          break
        case 'ESCALATION':
          this.state.processName = 'Start Escalation Process'
          break
      }

      this.state.process = {
        name: '',
        type: type,
        assignedIdRequest: '',
        objectIdRequest: this.objectId,
        objectTypeRequest: this.objectType,
        description: '',
        notification:  false
      }
      const modal = this.$refs.formProcessRef as typeof BaseModal
      modal.showBaseModal();
    },
    onSaved() {
      const all = this.$refs.allProcess as typeof AllProcess;
      all.paginationLoad();

      const active = this.$refs.activeProcess as typeof ActiveProcess;
      active.paginationLoad();

      const complete = this.$refs.completeProcess as typeof CompleteProcess;
      complete.paginationLoad();

      const modal = this.$refs.formProcessRef as typeof BaseModal
      modal.hideBaseModal();
      store.commit(Mutations.ADDED_PROCESS)
    }

  }
})
